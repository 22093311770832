import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import GlobalStyles from "../styles/GlobalStyles"
import "../styles/App.css";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LandingStyles from "../styles/LandingStyles"
import Banner from "../img/banner"
import studentsWithPhone from "../img/raw/students01.jpg"
import hms_logo from "../img/raw/hms_logo.png"
import huhs_logo from "../img/raw/huhs_logo.png"
import camhs_logo from "../img/raw/camhs_logo.png"
import Overview from "../components/Overview"
import WhoIs from "../components/WhoIs"
import Faqs from "../components/Faqs"
import Interest from "../components/Interest"
import About from "../components/About"
import ContactUs from '../components/ContactUs';
// testing comment
const useStyles = createUseStyles({
    ...GlobalStyles(),
    ...LandingStyles()
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "75%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "75%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

 
 
function Landing() {
  const [confirmOpen, setConfirmOpen] = useState(true);
  const [contactOpen, setContactOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleContactOpen = () => setContactOpen(true);
  const handleInfoOpen = () => setInfoOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);
  const handleContactClose = () => setContactOpen(false);
  const handleInfoClose = () => setInfoOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
    return (
      <div className={classes.site}>
       <div className={classes.app}>
            <div className={classes.header}>
              <div className={classes.huhsBanner}>
                <div className={classes.bannerOverlay}>
                  <div className={classes.bannerImgContainer}>
                    <div className={classes.bannerImg}></div>
                  </div>
                  <div className={classes.bannerOverlaySpacer}>
                    </div>
                    < div className={classes.affiliateTitle}>
                    <div ><img className={classes.huhsBannerIcon} src={hms_logo} alt={""}/></div>
                        <div className={classes.huhsBannerIcon2} ><Banner/></div>
                    </div>
                    <div className={classes.huhsTitle}>
                      <span>The Harvard iWellness Study</span>
                    </div>
                </div>
              </div>
            </div>
           <div className={classes.landingContent}>
             <div className={classes.landingContentBox}>
              <Overview/>
             </div>
             <div className={classes.landingContentBox}>
              <WhoIs/>
             </div>
             <div className={classes.landingContentBox}>
              <Faqs/>
             </div>
             <div className={classes.landingContentBox}>
              <Interest/>
             </div>
           </div>
            <div className={classes.footer}>
            <div>
      <Button variant="text" className={classes.sideFooterButton} onClick={handleContactOpen}>Contact<br/> Us</Button>
        <Dialog 
          onClose={handleContactClose} 
          open={contactOpen}
          fullWidth={"false"}
          fullScreen={fullScreen}
          maxWidth={"sm"}
          scroll={"paper"}
          >
          <DialogTitle>
           <ContactUs/>
          </DialogTitle>
          <br/><br/>
          <DialogActions>
            <div className={classes.diagCloseButton}>
            <Button variant={"outlined"} className={classes.sideFooterButton} onClick={handleContactClose}>Close</Button>

            </div>
          </DialogActions>
        </Dialog>
      </div> 
                <div>
      <Button variant="contained" className={classes.participateButton} onClick={handleConfirmOpen} disabled={true} >Study Has Ended</Button>
      <Modal
        open={confirmOpen}
        onClose={""}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Thanks for your interest in the iWellness Study. We are no longer enrolling participants in the study at this time. If you are looking for mental health resources, please visit the Counseling and Mental Health Services website at: https://camhs.huhs.harvard.edu/. You can also call the CAMHS Cares Line and speak with a counselor 24/7 if you are in need of immediate help. 
          
          <br/><br/>
          
          </Typography>
        </Box>
      </Modal>
    </div>
      <div>
      <Button variant="text" className={classes.sideFooterButton} onClick={handleInfoOpen}>Study<br/>Team</Button>
        <Dialog 
          onClose={handleInfoClose} 
          open={infoOpen}
          fullWidth={"false"}
          fullScreen={fullScreen}
          maxWidth={"lg"}
          scroll={"paper"}
          >
          <DialogTitle>
            <About/>
          </DialogTitle>
          <br/><br/>
          <DialogActions>
            <div className={classes.diagCloseButton}>
            <Button variant={"outlined"} className={classes.sideFooterButton} onClick={handleInfoClose}>Close</Button>

            </div>
          </DialogActions>
        </Dialog>
      </div>    
            </div>
            <div className={classes.footerPadding}></div>
       </div>
       </div>    
        
    )
}

export default Landing
