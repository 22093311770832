import React from 'react'
import {createUseStyles} from 'react-jss'
import CAMHS from "../img/raw/camhs_logo.png"
import LandingStyles from "../styles/LandingStyles"

const useStyles = createUseStyles({
    ...LandingStyles()
})
function WhoIs() {
  const classes = useStyles();

  return (
    <div className={classes.urgentContainer}>
      <div>
        <img src={CAMHS} alt={""}/>
      </div>
      <div className={classes.urgentContainerText}>
        <p>
          If you are in need of urgent help or would like to talk to a counselor immediately, please call the CAMHS CARES 24/7 therapy line at: <br/> <span className={classes.urgentNum}>617 495-2042</span>
        </p>
        </div>
    
    </div>
  )
}

export default WhoIs