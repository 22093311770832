const theme = () => ({
    title:{
      position:"relative",
       width:"100%",
       display:"flex",
       flexDirection:"column",
       justifyContent:"center",
       alignItems:"center",
       color:"#fff",
       background:"rgb(164,28,48)"
    //    border:"2px dashed red"
    },
    description:{
        position:"relative",
       width:"calc(100% - 4rem)",
       marginLeft:"2rem",
       display:"flex",
       flexDirection:"column",
       justifyContent:"center",
       alignItems:"center",
    //    border:"2px dashed red"
    },
    menuBar:{
        marginTop:"2rem",
        width:"100%",
        display:"flex",
        
        justifyContent:"space-evenly",
        alignItems:"center",
        
        // border:"2px dashed red"
    },
    menuButtonDiv:{
        width:"10rem",
        height:"10rem",
        border:"2px dashed red"
    }
});
 
export default theme