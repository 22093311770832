import studentsWithPhone from "../img/raw/students01.jpg"

const theme = () => ({
    huhsBanner:{
      position:"relative",
       width:"100%",
      //  padding:"1rem 0"
    },
    huhsBannerIcon:{
      "@media (max-width: 720px)": {
        height:"3.2rem",
        marginTop:"0.1rem",
      },
      "@media (min-width: 720px)": {
        height:"4.5rem",
      },
       display:"flex",
       alignItems:"center",
    },
    huhsBannerIcon2:{
      "@media (max-width: 720px)": {
        height:"2.25rem",
        marginTop:"0.1rem",
      },
      "@media (min-width: 720px)": {
        height:"2.9rem",

      },
     
       display:"flex",
       paddingLeft:"0.5rem",
       alignItems:"center",
       
    },
    bannerImgContainer:{
      height:"25rem",
      width:"100%",
    },
    bannerImg:{
      height:"100%",
      width:"100vw",
      maxWidth:"1400px",
      backgroundImage:"url(https://aws-website-huhssurvayparticipation-ccqdl.s3.amazonaws.com/img/AdobeStock_84383928.jpeg)",
      backgroundPosition:"center",
      backgroundSize:"cover",
      backgroundRepeat:"no-repeat",
      zIndex:3,
    },
    huhsBannerImage:{
      position:"relative",
      "@media (max-width: 720px)": {
        maxWidth:"100%",
        height:"auto",
      },
      "@media (min-width: 720px)": {
        height:"60vh",
      },
 },
    huhsTitle:{
      position:"relative",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      color:"rgb(255,255,255)",
      width:"calc(100% - 1rem)",
      fontSize:"1.5rem",
      fontWeight:"bolder",
      // height:"3rem",
      textAlign:"center",
      letterSpacing:"0.035rem",
      overflow:"hidden",
      background:"rgb(164,28,48)",
      padding:"0.5rem 0.5rem", 
      // border:"2px dashed red",
      zIndex:1,
    },
   
    affiliateTitle:{
      position:"relative",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      color:"#fff",
      fontSize:"1.2rem",
      fontWeight:"900",
      top:"0.15rem",
      right:"0.4rem",
      width:"100%",
      textAlign:"center",
      height:"3rem",
      letterSpacing:"0.035rem",
      overflow:"hidden",
      background:"rgba(255,255,255,1)",
      padding:"0.5rem 0 0.5rem 0", 
    },
    bannerOverlay:{
      position:"relative",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      // border:"2px dashed red",
      width:"100%",
    },
   
    landingContent:{
      position:"relative",
      // top:"40rem",
      width:"100%",
      height:"100%",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      // border:"2px dashed red",
    },
    landingContentBox:{
      position:"relative",
      width:"calc(100%)",
      height:"calc(100% - 2rem)",
      textAlign:"center",
      color:"#eee",
      fontSize:"1.2rem",
      background:"rgb(52,58,64)",
    },
    titleContent:{
      position:"relative",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      padding:"2rem 2rem 0rem 2rem",
      textAlign:"center",
    },
    removeUlPadding:{
      paddingTop:0,
      marginTop:0,
            // border:"2px dashed red",

    },
    titleCallToAction:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      fontSize:"1rem",
      // fontWeight:"600",
      textAlign:"center",
      lineHeight:"1.5rem",
      padding:"0 0 2rem 0",
      // border:"2px dashed red"
    },
    bullets:{
      position:"relative",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      // fontSize:"1rem",
      padding:"0rem 1.75rem",
      textAlign:"left",
      // border:"2px dashed red",
    },
    bulletIntro:{
    
      width:"calc(100%)",
      fontWeight:"600",
      marginLeft:"-2rem",

    },
    urgentContainer:{
      "@media (max-width: 720px)": {
        flexDirection:"column",
        padding:"2rem 0 1rem 0"
      },
      "@media (min-width: 720px)": {
        flexDirection:"row",
        padding:"1rem 0 1rem 0"

      },
      width:"calc(100% )",
      // marginLeft:"0.5rem",
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
      alignItems:"center",
      background:"#fff",
      color:"rgb(52,58,64)",
      textAlign:"left",
      // padding:"2rem 2rem 1rem 2rem",
      // border:"2px dashed red",
      // borderRadius:"5px",
    },
    urgentContainerText:{
      // position:"relative",
      width:"calc(20rem)",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      background:"#fff",
      
      color:"rgb(52,58,64)",
      textAlign:"center",
      padding:"1rem 2rem",
      // border:"2px dashed red"
    },
    urgentNum:{
      lineHeight:"3rem",
      fontSize:"1.3rem",
      fontWeight:"bolder",
    },
    footerPadding:{
      height:"4.75rem",
      width:"calc(100% - 10rem)",
    },
    highlightCrimson:{
      color:"rgb(164,28,48)"
    },
    faqsContent:{
      background:"rgb(41,51,82)",
      padding:"0.25rem 0",
    },
    faqBox:{
      textAlign:"left",
     
      width:"calc(100% - 1rem)",
      marginLeft:"0.5rem",
            background:"rgb(41,51,82)",

    },
    faqQA:{
      color:"rgb(0,0,0) !important",
      fontSize:"1srem !important",
      fontWeight:"600 !important",
    },
    interest:{
      width:"calc(100% - 5rem)",
      // marginLeft:"2.5rem",
      background:"rgb(41,51,82)",
      padding:"0.25rem 2.5rem",

    },
    confirmButtonDiv:{
      width:"100%",
      display:"flex",
      justifyContent:"center"
    },
    picBar:{
      display:"relative",
      width:"100%",
      height:"10rem",
      display:"flex",
      justifyContent:"space-evenly",
      alignItems:"center",
      // border:"2px dashed red",
    },
    profilePic1:{
      display:"absolute",
      display:"flex",
      justifyContent:"center",
       alignItems:"center",
       height:"10rem",
       padding:"1rem",
       borderRadius:"50%",
       
      //  border:"2px dashed red"
    },
    profileBox:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
       alignItems:"center",
       height:"10rem",
       textAlign:"center",
      //  border:"2px dashed red"
    },
    diagCloseButton:{
      width:"100%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    },
    hideMe:{
      visibility:"hidden",
    },
    participateButton:{
      background:"rgb(164,28,48) !important",
      // padding:"0.35rem 2em !important",
      fontSize:"1rem !important",
      // margin:"0 !important"
    },
    email:{
      display:"flex",
      justifyContent:"center",
      fontSize:"1.4rem",
      paddingTop:"1rem",
    },
    photosBox:{
      display:"flex",
      justifyContent:"space-evenly",
      width:"100%",
      height:"5rem",
      padding:"6rem 0 5rem 0",
      // border:"2px dashed red"
    },
    photosSection:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
    },
    photosPic:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      borderRadius:"100%",
      width:"10rem",
      height:"10rem"
    },
    photosName:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      padding:"1rem 0",
    },
    sideFooterButton:{
      color:"rgb(164,28,48) !important",
      fontSize:"1rem !important",
      fontWeight:"600 !important",
      lineHeight:"1rem !important",
      textAlign:"center !important",
      display:"flex !important",
      justifyContent:"center !important",
      alignItems:"center !important",
      // padding:"0 !important",
      // margin:"0 !important",
    }
   
     
   });
 
   export default theme