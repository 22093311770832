const theme = () => ({
   site:{
      width:"100%",
      height:"100%",
      display:"flex",
      justifyContent:"center",
      background:"#eee",
      //  border:"2px dashed red"

   },
    app:{
       marginTop:"0.25rem",
       width:"100%",
       maxWidth:"1400px",
       height:"100%",
       background:"#fff", 
      //  border:"2px dashed red"
    },
    header:{
       width:"100%",
      //  border:"2px dashed red"
    },
    footer:{
       position:"fixed",
       display:"flex",
       justifyContent:"space-around",
       width:"calc(100% - 4px)",
       maxWidth:"calc(1400px)",
       bottom:0,
       padding:"1rem 0",
       background:"rgba(255,255,255,1)",
       borderTop:"0.5rem solid rgb(164,28,48)",
      //  borderLeft:"2px solid #000",
      //  borderRight:"2px solid #000",
       zIndex:2,
    },

   
   });
 
   export default theme