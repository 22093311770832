import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import GlobalStyles from "../styles/GlobalStyles"
import SelectionStyles from "../styles/SelectionStyles"
import Button from '@mui/material/Button';
import QRCode from "react-qr-code";

const useStyles = createUseStyles({
    ...GlobalStyles(),
    ...SelectionStyles()
})

function Selection(props) {
    const classes = useStyles();
    const wLink = "https://wysabuddy.app.link/?$deeplink_path=referrer%3Freferrer%3Dharvard%26channel%3Dtech_evaluation%26code%3Dwysamfc4xjdk"
    const app = props.app;
    const appId = props.appId;
  return (
    <div className={classes.site}>
       <div className={classes.app}>
            <div className={classes.header}>
                <div div className={classes.title}>
                    <h1>Thank you!</h1>
                    <h4>Your enrollment is now complete.</h4>
                </div>
            </div>
            <div className={classes.content}>
                <div className={classes.description}>
                    <h3>You got: Wysa</h3>
                    <p>
                        The Wysa app provides 24/7, emotional support to users through an app-based AI chatbot system. All Wysa chats are anonymous. The AI-based bot uses CBT, DBT, meditation, breathing, yoga, motivational interviewing, and micro-actions to help you manage your emotions and encourage mental well-being. 
                        <br/><br/>
                        You can chat with the AI-based bot, Wysa, or choose to complete different self-care exercises. These exercises are grouped into tool packs, which include dealing with relationships, overcoming loneliness, managing mood, among many others. 
                        <br/><br/>
                        In addition, there are over a hundred AI-guided self-care exercises which are backed by science and handpicked by therapists. Wysa does not ask for any user registration or account profile creation during the setup of the app.
                    </p>
                </div>
                <div className={classes.menuBar}>
                    {/* <div className={classes.menuButtonDiv}>

                    </div> */}
                    <div className={classes.menuButtonDiv}>
                        <QRCode value = {wLink + appId}/>
                    </div>
                    <div className={classes.menuButtonDiv}>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Selection