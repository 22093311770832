import React from 'react'
import {createUseStyles} from 'react-jss'

import LandingStyles from "../styles/LandingStyles"
import Leslie from "../img/raw/Leslie.jpg"
import Giang from "../img/raw/Giang2.jpg"
const useStyles = createUseStyles({
    ...LandingStyles()
})
function About() {
  const classes = useStyles();

  return (
    <div>
      <h2>The iWellness Team:</h2>
     
          <p>The lead investigators for this study are Leslie Tarver, M.D. and Giang Nguyen, M.D.</p>

        <div className={classes.photosBox}>
          <div className={classes.photosSection}>
            <div ><img className={classes.photosPic} src={Leslie}/></div>
            <div className={classes.photosName}>Leslie Tarver, M.D.</div>
          </div>
          <div className={classes.photosSection}>
            <div ><img className={classes.photosPic} src={Giang}/></div>
            <div className={classes.photosName}>Giang Nguyen, M.D.</div>
          </div>
        </div>

        <p>The iWellness Study is a collaboration between:</p>
        <ul>
          <li>Harvard University Health Services (HUHS) Counseling and Mental Health Services (CAMHS)</li>
          <li>Harvard Medical School Mental Health for All Lab led by Dr. Vikram Patel</li>
          <li>Harvard Graduate Student Mental Health Initiative led by Dr. Paul Barreira </li>
        </ul>
        <p>We are funded by The Pershing Square Fund for Research on the Foundations of Human Behavior.</p>
        <p>This study has been reviewed and approved by the Harvard University Area Institutional Review Board.  </p>
     
    </div>
  )
}

export default About