import React, { useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import './styles/App.css'
import GlobalStyles from "./styles/GlobalStyles"
import Layout from "./components/Layout"
import SelectionA from "./pages/SelectionA"
import SelectionB from "./pages/SelectionB"
const queryString = require('query-string');
const useStyles = createUseStyles({
  ...GlobalStyles(),
})
function App() {
  const classes = useStyles();
  const [app, setApp]= useState("")
  const [appId,setAppId] = useState("")
    useEffect(()=>{
        // preventIosTouch()
       getParameters();
      },[])
   


     async function getParameters(){
      const parsed = await queryString.parse(window.location.search)
      try{
        setApp(parsed.app)
      } catch (err){
        console.log("app error",err)
      }
      try{
        setAppId(parsed.appId)
      } catch (err){
        console.log("app error",err)
      }
      // setQueries(parsed)
      // console.log(parsed.app)
     } 
    function preventIosTouch(){
        document.addEventListener(
          'gesturestart', (e) => e.preventDefault()
      );
      }

    if (app == 192387)  {
      return (
        <div >
          <SelectionA app={app} appId={appId}/>
        </div>
      
      )} else if (app == 923847){
        return (
          <div >
            <SelectionB  app={app} appId={appId}/>
          </div>
      )} else if (app == ""){
        return (
        <div >     
          <Layout/>
        </div>
        )
      } else {
        return (
          <div >     
            <Layout/>
          </div>
          )
      }
 
}
export default App