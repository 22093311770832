import React from 'react'
import {createUseStyles} from 'react-jss'
import LandingStyles from "../styles/LandingStyles"

const useStyles = createUseStyles({
    ...LandingStyles()
})

function ContactUs() {
    const classes = useStyles();
  return (
    <div>
        <h2>CONTACT US:</h2>
        <p>If you have questions, concerns or would like to talk to the research team, please contact us at <br/>
<a className={classes.email} href="">support@iwellnessstudy.com</a><br/>A study team member will 
respond within one business day.</p>
    </div>
  )
}

export default ContactUs