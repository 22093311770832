import React from 'react'
import {createUseStyles} from 'react-jss'
import LandingStyles from "../styles/LandingStyles"

const useStyles = createUseStyles({
    ...LandingStyles()
})
function Overview() {
    const classes = useStyles();
  return (
    <div>
        <div className={classes.titleContent}>
            <p>This study aims to evaluate how access to a mobile app can improve mental wellness for students.  
            
            </p>
        </div>
       
        <div className={classes.bullets}>

        <ul className={classes.removeUlPadding}>
        <p className={classes.bulletIntro}> Here are some key points about the study:</p>
        <li> It will provide you with an app to support your mental and emotional wellbeing.</li> <br/>
				<li>Your use of the app is in addition to accessing care at HUHS and it will not replace or affect your care in any way. </li>	<br/>
				<li>Your interactions with the app are completely confidential.</li><br/>
				<li>You will be sent 3 short surveys over 8 weeks and will receive $10 for each survey you complete ($30 total).</li><br/>
    	</ul>
      <div className={classes.titleCallToAction} ><h3>Your participation can help improve mental health services at Harvard!</h3><p>For more information, see the FAQs below.<br/>To enroll and access the 
app, click the Participate button below.</p></div>  
        </div>
    </div>
  )
}

export default Overview