//Layout.js handles general page structure and theming

import React, {useState, useEffect, useRef} from 'react'
import {createUseStyles, useTheme,ThemeProvider} from 'react-jss'
import Auth from '../components/Auth'
import GlobalStyles from "../styles/GlobalStyles"
import ThemeStyles from "../styles/ThemeStyles"
import gsap from 'gsap'

const useStyles = createUseStyles(({
    ...GlobalStyles(),
}))

const theme = {
   ...ThemeStyles()
}

function Layout() {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <div 
            // className={classes.app}
            >
                <Auth/>
            </div>
        </ThemeProvider>
             
       
    )
}

export default Layout