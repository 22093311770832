import React from 'react'
import {createUseStyles} from 'react-jss'
import LandingStyles from "../styles/LandingStyles"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = createUseStyles({
    ...LandingStyles()
})

function Faqs() {
    const classes = useStyles();

  return (
    <div>
        <div className={classes.faqsContent}>
            <h3>Frequently asked questions:</h3>
            <div className={classes.faqBox}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.faqQA}>Why does this matter? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          There is a growing mental health crisis for university students across the country. The demand for counseling services has grown at least five times faster than average student enrollment. Additionally, the COVID-19 pandemic has led to worsening mental health challenges and made it difficult for many students to access care. Mobile apps have the potential to provide an immediate tool to support student mental health, bridge the gap to care, and potentially alleviate demand on mental health services. However, we need more information on how effective mobile apps are for this purpose. With your help, we can learn more about the ways mobile apps can promote student mental wellness.           </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
           <Typography className={classes.faqQA}>If I choose to participate, what do I have to do and how long will it take?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You will need to download your assigned app and use it for 8 weeks. You will be asked to complete a few confidential surveys which take less than 5 minutes to complete. You will receive a survey via email at 2, 4 and 8 weeks after enrolling in the study. You can still make appointments and seek help from CAMHS at any point while participating in the study.            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.faqQA}>What are the possible benefits?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Some benefits include increased support for your mental health, increased screening of your mental health symptoms and possible connection to care at HUHS CAMHS. You will also be contributing to research that could potentially improve mental health services at Harvard.        
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
           <Typography className={classes.faqQA}>What are the possible risks?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          It is possible that you may not find the mobile app you are assigned to be useful or that it leads to discomfort. There is the potential risk to privacy by sharing information with the study or on your assigned app, however careful privacy and confidentiality procedures are in place to protect your information. You are also free to stop using the app at any point during the study. If your symptoms worsen during the study, you can call the CAMHS CARES line at 617-495-2042 and speak to a counselor 24/7.           </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
           <Typography className={classes.faqQA}>Will I be compensated for participating in this research? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You will receive $10 for each survey you complete or $30 for completing all three surveys over the 8 week study period.            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
           <Typography className={classes.faqQA}>How will my privacy be protected?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The data we collect for the study will be de-identified and stored on a secure, encrypted server. Personal information such as email addresses or phone numbers will be stored separately from study data. We will only use your email address to send important study notifications and  the secure surveys. The messages you send via the mobile app are confidential and abide by the privacy policies of the assigned app. No personal data will be collected by the app assigned to you. The information you provide in the app or in the surveys will not be linked to your medical record.            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
           <Typography className={classes.faqQA}>I have more questions. Who can I contact? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>You can reach study staff at: support@iwellnessstudy.com</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
        </div>
    </div>
  )
}

export default Faqs