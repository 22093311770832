import React, {useState} from 'react'
import {createUseStyles} from 'react-jss'
import GlobalStyles from "../styles/GlobalStyles"
import SelectionStyles from "../styles/SelectionStyles"

const useStyles = createUseStyles({
    ...GlobalStyles(),
    ...SelectionStyles()
})

function Selection(props) {
    const classes = useStyles();
  return (
    <div className={classes.site}>
       <div className={classes.app}>
            <div className={classes.header}>
                <div div className={classes.title}>
                    <h1>Thank you!</h1>
                    <h5>Your enrollment is now complete.</h5>
                </div>
            </div>
            <div className={classes.content}>
                <div className={classes.description}>
                    <p>we</p>
                </div>
                <div className={classes.menu}></div>
            </div>
        </div>
    </div>
  )
}

export default Selection