
//Auth.js checks authentication and routes the user to the appropriate pages

import React from 'react'
import Landing from "../pages/Landing"
function Auth() {
    return (
        <>
            <Landing/>
        </>
    )
}

export default Auth