import React from 'react'
import {createUseStyles} from 'react-jss'
import LandingStyles from "../styles/LandingStyles"


const useStyles = createUseStyles({
    ...LandingStyles()
})

function Interest() {
    const classes = useStyles();

  return (
    <div className={classes.interest}>
        <h3>Interested in joining? <br/>Click the <span >Participate</span> button!</h3>
    </div>
  )
}

export default Interest